
import SocialLinks from '@/components/landing/SocialLinks.vue'
import Drawer from '@/components/landing/Drawer.vue'
import Footer from '@/components/landing/Footer.vue'
import FlyoutMenu from '@/components/landing/FlyoutMenu.vue'
import Icon from '@/components/UntitledUI/Icon.vue'
import Logo from '@/components/common/Logo.vue'

export default {
    name: 'LandingHeader',
    components: { FlyoutMenu, Footer, Drawer, SocialLinks, Icon, Logo },
    props: {
        source: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            showDrawer: false,
            showIndustries: false,
            showProducts: false,
            showKnowledge: false,
            showSubLandings: false
        }
    }
}
