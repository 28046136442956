
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    components: { Icon },
    props: {
        items: {
            required: true,
            type: Array
        },
        id: {
            required: true,
            type: String
        },
        options: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            splide: null
        }
    },
    mounted () {
        this.init()
    },
    updated () {
        this.init()
    },
    beforeDestroy () {
        this.destroy()
    },
    methods: {
        destroy () {
            if (this.splide) {
                this.splide.destroy()
            }
        },
        init () {
            this.destroy()
            let options = {
                arrows: this.options && this.options.pagination,
                focus: 0,
                autoWidth: true,
                pagination: false,
                type: 'loop',
                direction: 'rtl'
            }
            if (this.options) {
                options = Object.assign(options, this.options)
            }
            this.splide = new Splide(`#${this.id}`, options)

            const plugins = {}
            if ('autoScroll' in options) {
                plugins.AutoScroll = AutoScroll
            }
            this.splide.mount(plugins)
        }
    }
}

