export default [
    {
        num: 736000,
        title: 'نشاط العملاء'
    },
    {
        prefix: 'ر.س',
        num: 57025922,
        title: 'حجم التوفير من التكاليف القانونية المباشرة من خلال استخدام قانونية'
    },
    {
        num: 803182,
        title: 'إجمالي ساعات العمل التي تم توفيرها من خلال استخدام قانونية'
    }
]
