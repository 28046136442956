export default [
    {
        src: '/landing/companies/MOI.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/ARAMCO.webp',
        alt: 'ARAMCO'
    },
    {
        src: '/landing/companies/SNB.webp',
        alt: 'Saudi National Bank'
    },
    {
        src: '/landing/companies/PIF.webp',
        alt: 'Public Investment Fund'
    },
    {
        src: '/landing/companies/AC.webp',
        alt: 'Albilad Capital'
    },
    {
        src: '/landing/companies/GOFSI.webp',
        alt: 'General Organization for Social Insurance'
    },
    {
        src: '/landing/companies/SDB.webp',
        alt: 'Social Development Bank'
    },
    {
        src: '/landing/companies/MOBILY.webp',
        alt: 'Mobily'
    },
    {
        src: '/landing/companies/MOEP.webp',
        alt: 'Ministry Of Economy & Planning'
    },
    {
        src: '/landing/companies/MOHU.webp',
        alt: 'Ministry Of Hajj And Umrah'
    },
    {
        src: '/landing/companies/IMI.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/KPMG.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/NEC.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/GAFC.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/DERAAH.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/K&A.webp',
        alt: 'K & A'
    },
    {
        src: '/landing/companies/KS_RDA.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/NOVA.webp',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/SCTC.webp',
        alt: 'Communication, Space & Technology Commission'
    }
    //, {
    // src: '/landing/companies/ITTIHAD_CLUB.webp',
    // alt: 'ITTIHAD CLUB'
    // },
]
